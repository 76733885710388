
:root{
  --themeclr: #F6DF2D;
  --textclr:#DEC508;
}
a{text-decoration: none !important;}
body{
  background-color: #000 !important;
  /* background-image: url(./Assets/images/bg.png); */
  color:#fff;
  font-family: medium;
}
@font-face {
  font-family: themefont;
  src: url(./Assets/Font/Gilroy-Black.ttf);
}
@font-face {
  font-family: thin;
  src: url(./Assets/Font/Gilroy-Thin.ttf);
}
@font-face {
  font-family: bold;
  src: url(./Assets/Font/Gilroy-Bold.ttf);
}
@font-face {
  font-family: extrabold;
  src: url(./Assets/Font/Gilroy-ExtraBold.ttf);
}
@font-face {
  font-family: medium;
  src: url(./Assets/Font/Gilroy-Medium.ttf);
}
@font-face {
  font-family: normal;
  src: url(./Assets/Font/Gilroy-Regular.ttf);
}
@font-face {
  font-family: light;
  src: url(./Assets/Font/Gilroy-Light.ttf);
}
.container.container-theme {
  max-width: 95%;
}
.normal{
  font-family: normal;
}
.bold{
  font-family: bold;
}
.innerheading {
  font-family: extrabold;
}
.themeclr{
   color: var(--themeclr) !important;
}
.textclr{
  color:var(--textclr)
}
.flex-1 > * {
  flex: 1;
}
.jc-between{
  justify-content: space-between;
}
.br-14{
  border-radius: 14px;
}
.bgtheme{
  background-color: var(--themeclr);
}
button.ovalbtn {
  background: var(--themeclr);
  border-radius: 30px;
  padding: 4px 10px;
  font-weight: 700;
  border: 1px solid var(--themeclr);
}
.btn.getstarted {
  background: var(--themeclr);
  font-family: 'extrabold';
  border-radius: 0;
  position: relative;
  min-width: 120px;
  padding: 10px 20px;
}
.launchpad .launchbox .btn.getstarted,
.lockedstake .launchbox .btn.getstarted{
  background-color: #4008DE;
  color: #fff;
}
.launchpad .innerheading, .lockedstake .innerheading{
  color: #000;
}
.smallp {
  max-width: 50%;
  margin: auto;
  color: #cbcbcb;
}
button.chocobtn {
  background: var(--themeclr);
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 20%;
  
  font-weight: 700;
  border: 1px solid var(--themeclr);
}
button.btn.getstarted.dark {
  background: #000;
  color: #fff;
}
button.btn.getstarted:after {
    content: "";
    width: 100%;
    height: 100%;
    border: 1px solid white;
    position: absolute;
    left: -5px;
    bottom: 4px;
}
section {
  margin-top: 65px;
  margin-bottom: 65px;
}
button.btn.getstarted:after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid white;
  position: absolute;
  left: -5px;
  bottom: 4px;
  transition: 0.2s;
}
.ban-cont p, .features-list p {
  font-family: normal;
  color: #CBCBCB;
  line-height: 2;
}

.btn.btn-theme{
  background-color: var(--textclr);
}

/* header */
.land .navbar-nav .getstarted {
  display: none;
}
.header .bg-dark {
  background: #000 !important;
}
.header .navbar-nav a:hover,
.header .navbar-nav a.nav-link.active {
  border-bottom: 3px solid var(--textclr);
}
.header .navbar-nav a {
  border-bottom: 3px solid transparent;
  margin-left: 20px;
  margin-right: 20px;
}
.header .navbar-nav a:last-child{
  margin-right: 0;
}
.header .navbar-nav a:first-child{
  margin-left: 0;
}
.lockedstake nav.navbar .btn.getstarted,
.launchpad nav.navbar .btn.getstarted {
  max-width: 150px;
}
/* tabs */
ul.nav-tabs {
  max-width: 435px;
  flex-flow: nowrap;
  overflow-x: auto;
  padding-bottom: 15px;
}
ul.nav-tabs li{
  flex: 1;
  white-space: nowrap;
}
li.nav-item button {
  background: transparent !important;
  border-color: transparent !important;
  color: #ffffff8c;
  width: 100%;
}
li.nav-item button:hover{
  color: #fff !important;
}
li.nav-item button.active {
  color: #000 !important;
  background-color: #f6df2d !important;
  border-radius: 5px;
}
ul.nav-tabs{
  border: 0 !important;
}


.news{position: relative;}
/* .newshead{margin-top:-24px;} */
.news .items{position: relative;height: 90vh;}
.news .itemsfles{    display: flex;
  align-items: start;
  flex-direction: column;
  max-height: 100vh;
  justify-content: center;
  width: 50%;
  height: 100%;
  min-height: 80vh;
  position: absolute;
  bottom: 0;
  left: 41px;
  right: 0;
  top: 0;
}
  .news .itemsfles h2{font-size: 50px;font-weight: 600;}
  .news .itemsfles p{font-size: 16px;font-weight: 400;}
  .news .owl-carousel .owl-nav{position: absolute;    bottom: 50px;
    right: 80px;}
    .news .owl-carousel .owl-nav button.owl-next{background-color: #ffffff80;color: #5e5e5e;
      font-size: 28px;

      border-radius: 50px;
      width: 42px;}
    .news .owl-carousel .owl-nav button.owl-prev{background-color: #ffffff80;color: #5e5e5e;
      font-size: 28px;

      border-radius: 50px;
      width: 42px;}
      .news .owl-carousel .owl-nav button.owl-prev:hover,.news .owl-carousel .owl-nav button.owl-next:hover{background-color: #fff;}
      .Editor_pick{cursor: pointer;}
      .Editor_pick h2{color: #fff;    font-family: extrabold;padding: 30px 0;}
      .col_token:nth-child(even) .token_list{background-color: #DEC508;border: 1px solid #DEC508;border-radius: 5px;margin-top: 20px;transition: all 0.5s ease;margin-bottom: 6px;}
      .token_list{background-color: #fff;border: 1px solid #fff;border-radius: 5px;margin-top: 20px;transition: all 0.5s ease;margin-bottom: 6px;}
      .tokenimg{display: flex;align-items: center;justify-content: center;border-radius: 5px;min-height: 250px;max-height: 250px;overflow: hidden;}
      .tokenimg img{min-height: 250px;object-fit: cover;max-width: 100%;}
      .token_items h2{color: #000;font-family: extrabold;padding: 8px;margin-bottom: 0; font-size: 24px;}
      .token_items p{color: #000;padding: 8px;font-size: 14px;margin-bottom: 0;}
     .launchpad .token_list {
        background-color: #DEC508;
        border: 1px solid #DEC508;
        border-radius: 5px;
        margin-top: 20px;
        transition: all 0.5s ease;
        margin-bottom: 6px;
        margin-right: 8px;
    }
    .launchpad .col_token .token_list {
      background-color: #fff;
      border: 1px solid #fff;
      border-radius: 5px;
      margin-top: 20px;
      transition: all 0.5s ease;
      margin-bottom: 6px;
      margin-right: 8px;
  }
    .launchpad .tokenimg{display: flex;align-items: center;justify-content: center;border-radius: 5px;min-height: 250px;max-height: 250px;overflow: hidden;padding: 0;}
      .launchpad .tokenimg img{min-height: 250px;object-fit: cover;max-width: 100%;}
     .newdetail {display: flex;align-items: center;justify-content: center;min-height: 100vh;max-height: 100vh;overflow: hidden;cursor: pointer;}
     .newdetail img{min-height: 100vh;max-width: 100%;object-fit: cover;min-width: 100%;}
     .newdetailsec{background-color: #fff;margin-top: -100px;}
     .recent_post{background-color: #fff;margin-top: 50px;padding: 20px;}
     .no-bord {
      border: 0 !important;
    }
     .vdieo_hide{
      border: 1px solid #eee; 
         padding: 15px 15px 15px 15px;
      text-align: center;}
     .form_loist{background-color: #fff;margin-top: 50px;padding: 20px;}
     .newdetailsec h2{color: #000;font-size: 40px;
      padding: 30px 30px 10px 30px; 
  }
     .recent_post h2{color: #000;font-size: 18px;font-weight: 600;
     padding-bottom: 10px;
  }
     .form_loist h2{color: #000;font-size: 30px;font-weight: 600;
     padding-bottom: 10px;
  }
  .imgdive{  display: flex;padding: 0 5px 40px 5px ; 
    font-size: 0;
    flex-flow: row wrap;justify-content:center;
    gap: 1px;
    max-width: 800px;
    margin: 0 auto;}
      .imgs{  margin: 0; min-width: 201px;
    flex: 0.67; transition: .5s;display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-height: 250px;
    max-height: 250px;}
      .imgs img{max-width: 100%;min-height: 250px;object-fit: cover;}
  .recentblogdet{display: flex;align-items: center;justify-content: start;margin-bottom: 20px;}
  .recent_post a{color: #5e5e5e !important;text-decoration: none;font-size: 16px;font-weight: 500;margin-left: 20px;transition: all 0.5s ease;}
  .recent_post a:hover{color: #000 !important;text-decoration: none;font-size: 16px;font-weight: 500;margin-left: 20px;}
  .recent_post img{width: 80px;height: 70px;object-fit: cover;}
  .dayscmment{display: flex;align-items: center;padding: 0px 0px 0px 30px;cursor: pointer;}
  .dayscmment p{color: #818181;font-size: 14px;margin-right: 10px;}
  .newdetailsec .textconete{color: #818181;font-size: 14px;margin-right: 10px;padding: 0px 30px 30px 30px;}
  .dayscmment i{color: #818181;font-size: 14px;}

/* banner */

.heading{
  font-size: 55px;
}
.bannerimg img {
  width: 100%;
  max-width: 500px;
}

/* feature */
.features-list {
  border: 1px solid transparent;
  border-radius: 14px;
}
.features-list:hover {
    border: 1px solid var(--themeclr);
}

/* roadmap */
h6.roadmapbtn:after {
  position: absolute;
  content: "";
  height: 46px;
  width: 1px;
  background: white;
  top: -31px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

h6.roadmapbtn {
  position: relative;
}
.line:after {
  content: "";
  height: 20px;
  width: 20px;
  background: #000000;
  display: block;
  color: var(--themeclr);
  position: absolute;
  border-radius: 50%;
  border: 2px solid white;
  left: 0;
  right: 0;
  margin: auto;
  top: 26%;
  z-index: 1;
}

.car-item:after {
  width: 100%;
  content: "";
  height: 6px;
  background: var(--themeclr);
  position: absolute;
  left: 0;
  right: 0;
  /* top: 26%; */
  top:29%;
}

.fullinerelative{
  position: relative;
}
.fullline {
  position: absolute;
  height: 6px;
  width: 100%;
  background: #2D2D2D;
  top: 77px;
  border-radius: 10px;
}
.roadmap .owl-item:first-child .car-item:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.logogrid{display: grid;grid-template-columns: repeat(4 , 1fr);gap: 50px;    place-items: center;}
.logogrid img{    width: 10vw;
  max-height: 3vw;}
.notyetstart .line:after {
  background: var(--themeclr);
  border-color: #817E7E;
  border-width: 3px;
}
.owl-carousel .owl-nav button.owl-next:hover ,
.owl-carousel .owl-nav button.owl-prev:hover {
  background: transparent;
}
.notyetstart:after {
  background: transparent;
}
.inprogress:after {
  width: 50%;
}
.car-item h6:first-child {
  margin-bottom: 66px;
}

.car-item h6:first-child {
  margin-bottom: 66px;
}
.car-item{
  text-align: center;
  position: relative;
  width: 100%;
}
.car-item p {
  color: #CBCBCB;
  font-size: 15px;
  padding-right: 15px;
  min-height: 88px;
}
.infos p , .marketcapital  p{
  font-size: 18px;
}
.roadmap .owl-nav button span {
  margin-top: 0 !important;
}
.car-item .line + h6 {
  margin-top: 80px;
}
.car-item h6 {
  color: white;
}
h6.roadmapbtn {
  background: var(--themeclr);
  max-width: 114px;
  padding: 7px;
  border-radius: 20px;
  color: #000;
  margin: auto;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 11px;
}
.slider_sec_1  img{
  max-width: 170px;
  margin:20px auto;
  transition: all 0.5s ease;
  cursor: pointer;
  /* max-height: 110px;
  min-height: 110px; */
}

.slider_sec_1 .owl-carousel .owl-nav .owl-prev span,.slider_sec_1 .owl-carousel .owl-nav .owl-next span{
  /* display: none; */
  color: #97afd5;
}
.slider_sec_1 .owl-carousel .owl-nav .owl-prev span, .slider_sec_1 .owl-carousel .owl-nav .owl-next span {
  color: #97afd5;
  border: 1px solid #97afd5;
  height: 35px;
  width: 35px;
  display: inline-block;
  border-radius: 50%;
  font-size: 35px;
  font-weight: 100;
  line-height: 0.8;
  margin-top: 40px;
}

.slider_sec_1 .owl-item
{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 150px !important;
}
.slider_sec_1 .owl-item div,.slider_sec_1 .owl-item div a
{
    height: 100%;

}
.slider_sec_1 .owl-item .car-item > div{
  padding-top: 40px;
}



/* launchpad */
.bannerbox .innerheading {
  font-size: 45px;
  text-shadow: 0px 8px 2px #5f59592b;
}
.bordbox {
  border: 1px solid var(--themeclr);
}
.launchbox {
  background-color: #0F0F0F;
  border: 1px solid #313131;
  position: relative;
}

.launchpad button.chocobtn {
  position: absolute;
  right: 24px;
  top: -17px;
}
.progress {
  background-color: #272727;
  height: 10px;
}
.progress-bar {
  background: var(--textclr);
  border-radius: 5px;
}

.launchbox  h6 {
  font-family: 'bold';
}
.logocont img {
  border: 2px solid var(--themeclr);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 5px;
  object-fit: contain;
}
.logocont span {
  text-transform: uppercase;
  font-family: normal;
}
.launchpad .bgtheme .innerheading{
  text-shadow: 0px 8px 2px #5f59592b
}
.calcicon {
  width: 12px;
}


/* modal */
.modal-backdrop.show {
  opacity: .9;
}
.modal-content {
  background: #0F0F0F;
} 
.modal-backdrop {
    background-color: #2E2E28;
}
.yellowbox {
  border: 1px solid var(--themeclr);
  background: #000000;
  border-radius: 15px;
}
.bannerbox{
  min-height: 181px;
}
.modal .yellowbox.connect.text-center {
  max-width: 185px;
  margin: auto;
}
.modal .yellowbox img {
  max-width: 50px;
  height: auto;
}
.modal-sm {
  max-width: 400px;
  margin: auto;
}
.btn-link.close:hover{
  color: var(--themeclr);
}
.input-group input {
  background: #000 !important;
  border: none;
  border-radius: 8px;
  color: #fff;
  min-height: 48px;

}
.input-group.h-75 input{
  height: 75px;
}
span.input-group-text {
  background: #000;
  border: none;
  border-radius: 8px;
}
.input-group {
  border: 1px solid var(--themeclr);
  border-radius: 8px;
}
.input-group input:focus {
  box-shadow: none;
}
.form_loist label{color: #5e5e5e;font-size: 12px;}
.labelforme{display: flex;align-items: start;justify-content: center;flex-direction: column;    width: 100%;}
.form_loist .labelforme textarea{--webkit-appearance:none !important;appearance: none !important;border: 1px solid #5e5e5e33 !important;margin-top: 10px;width: 98%;}
.form_loist .labelforme textarea:focus{outline: none;}
.form_loist .labelforme input[type="text"]{--webkit-appearance:none !important;appearance: none !important;border: 1px solid #5e5e5e33 !important;
  max-width: 95%;
  min-width: 80%;    height: 40px;
  width: 100%;}
  .labelformecheck{display: flex;align-items: center;
    margin-bottom: 30px;
}
  .submitfomer{
    background-color: #F6DF2D;
    font-size: 14px;
    color: #000;
    text-decoration: none;
    border-radius: 5px;
    font-weight: 500;
    padding:12px 14px !important;
    height: 50px;
    margin-top: 30px;
    border: 1px solid #F6DF2D;
transition: all 0.5s ease;
  }
  .vdieo_hide iframe{    max-width: 100%;
    width: 550px;
    max-height: 300px;
    height: 300px;
    min-height: 300px;}
  .submitfomer:hover{background-color:transparent;color: #000;}
.labelformecheck p{color: #5e5e5e;font-size: 14px;margin-bottom: 0;margin-left: 10px;}
.form_loist .labelforme input:focus{outline: none;}
.inputfoe{display: flex;align-items: center;justify-content: space-between;margin-top: 10px;}
/* footer */
.footer {
  border-top: 1px solid var(--themeclr);
  padding-top: 25px;
  padding-bottom: 10px;
  margin-top: 54px;
}
.footer .textclr{
  font-family: light;
}
.footer ul li{
  list-style-type: none;
  display: inline-block;
}
.footer ul li{
  margin-left: 10px;
  margin-right: 10px;
}
.footer ul li:first-child{
  margin-left: 0;
}
.footer ul li span {
  color: var(--textclr);
  font-size: 25px;
}



@media(min-width:1601px){

  .car-item p{
    min-height: 78px;
}
.line:after{
    top:23%;
}
 
}

@media(max-width:1199px){
  .smallp {
    max-width: 100%;
  }
  .news .itemsfles{min-height: 60vh;}
  .news .itemsfles h2{font-size: 40px !important;}
  
.news .items{    height: 60vh;}
.news .owl-carousel .owl-nav{bottom: 50px;}
}

@media(max-width:992px){.news .itemsfles h2{font-size: 40px;}
.news .itemsfles {
  min-height: 50vh;
} 
}
@media(max-width:991px){
  .navbar-collapse{
          position: absolute;
    background: black;
    width: 100%;
    top: 87px;
    left: 0px;
    padding-left: 40px;
}
.news .items{    height: 90vh;background-size: 100%;}
.news .itemsfles {

  width: 100%;
}
.news .itemsfles p{width: 80%;}
.news .itemsfles h2{font-size: 36px;}
  .navbar-collapse a{
        max-width: max-content;
    max-width: fit-content;
  }
  .bannerbox .innerheading {
    font-size: 30px;
  }
  .header .navbar-nav a{
    margin: 0;
  }
  .heading {
    font-size: 40px;
}
.bannerimg img {
  max-width: 390px;
}
}
@media(max-width:767px){
  .infos p, .marketcapital p {
    font-size: 16px;
}

.news .owl-carousel .owl-nav {
  bottom: 0px;
}
.news .itemsfles {
  min-height: 41vh;
}
.news .items {
  height: 90vh;
  background-size: 100% 100%;
}
.navbar-brand img {
  height: 40px;
}

}
.logos_sec{max-width: 80%;margin: 0 auto;}
@media(max-width:575px){.newdetailsec{
  margin-top: -60px;
}
.logogrid{grid-template-columns: repeat(3,1fr);}}
@media(max-width:425px){
  .slider_sec_1 img {
      max-width: 99px;
      margin: 0px auto;
  }
  footer {text-align: center !important;margin: 0 auto !important;}
  .newdetail{min-height: unset;max-height: unset;}
  .newdetail img{min-height: unset;}
  .items .newdetail img{min-height: 100vh;}
  .news .itemsfles p{font-size: 14px;}
  .newdetailsec h2{font-size: 30px;}
  .connect p {
    font-size: 12px;
  }
  .news .itemsfles h2 {
    font-size: 24px !important;
}
  .news .owl-carousel .owl-nav button.owl-next,.news .owl-carousel .owl-nav button.owl-prev{font-size: 22px;     width: 32px;}
  .news .owl-carousel .owl-nav{left: 0;right: 0;}
}

@media(min-width:1800px){
  .heading{
    font-size: 75px;
  }
}

.news .Editor_pick.firstsec .token_list{
  height: 100%;
}
.newdetailsec h2 {
  font-weight: 900;
}
.newdetailsec .textconete b{
  font-size: 16px;
}
.launchpad .noticelink{
  color: #838383 !important;
  font-size: 18px;

}
.launchpad .menu_list{
 position:relative;

}
.launchpad .notice_link{
  position:absolute;
  right: 0px;

}

ul.nav-tabs.tab_size_small li
{
max-width: 100px !important;
}


/* .Editor_pick.firstsec .col_token{
  height: 100%;

} */